import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
// import Homepage from '@/views/Home' //主页
// import Login from "@/views/Login" //登陆
// import Register from "@/views/Register"; //注册
Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
    const storeMenus = localStorage.getItem("menus");
    if (storeMenus) {
        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        if (!currentRouteNames.includes('Manage')) {
            // 拼装动态路由
            const manageRoute = {
                path: '/', name: 'Manage', component: () => import('../views/Manage.vue'), redirect: "/appManage", children: [
                    { path: 'person', name: '个人信息', component: () => import('../views/Person.vue') },
                    { path: 'password', name: '修改密码', component: () => import('../views/Password.vue') }
                ]
            }
            const menus = JSON.parse(storeMenus)
            console.log('菜单', menus);
            menus.forEach(item => {
                if (item.path) {  // 当且仅当path不为空的时候才去设置路由
                    // let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('@/views/' + item.pagePath + '.vue')}
                    let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: (resolve) => require([`@/views/${item.pagePath}.vue`], resolve) }
                    manageRoute.children.push(itemMenu)
                } else if (item.children.length) {
                    item.children.forEach(item => {
                        if (item.path) {
                            // let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('@/views/' + item.pagePath + '.vue')}
                            let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: (resolve) => require([`@/views/${item.pagePath}.vue`], resolve) }
                            manageRoute.children.push(itemMenu)
                        }
                    })
                }
            })
            //获取当前路由对象名称数组
            const currentRouteNames = router.getRoutes().map(v => v.name)
            if (!currentRouteNames.includes("Manage")) {
                // 动态添加到现在的路由对象中去
                router.addRoute(manageRoute)
            }
        }

    }
}

// 重置我就再set一次路由
setRoutes()

router.beforeEach((to, from, next) => {
    localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
    store.commit("setPath")
    console.log('测试');
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    const storeMenus = localStorage.getItem("menus");

    // 处理路由逻辑
    if (!to.matched.length) {
        // 未找到路由的情况
        if (storeMenus) {
            return next("/404");
        } else {
            return next("/login");
        }
    }

    if (to.path === '/login') {
        if (user) {
            return next('/appManage');
        }
        return next();
    }

    if (!user) {
        return next('/login');
    }

    next();
});

export default router
